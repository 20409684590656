










































































































































































































import { SIDE_ORDER } from '@/services/order/models'
import Vue from 'vue'

export default Vue.extend({
  props: {
    tableData: {
      type: Array,
      required: true
    },

    loading: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    SIDES() {
      return SIDE_ORDER
    }
  }
})
